/* You can add global styles to this file, and also import other style files */
.font-12 {
    font-size: 12px;
}




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
